.priority-1 {
  font-size: 70px;
}

.priority-2 {
  font-size: 19px;
}

/*----- Fonts -----*/
/*----- Links -----*/
/*----- Retina -----*/
/*----- Border Radius -----*/
/*----- Opacity -----*/
/*----- Media Queries -----*/
/*----- Selection CSS -----*/
/*----- Box Sizing -----*/
/*----- Translate 2D mixin -----*/
/*----- Scale mixin -----*/
/*----- Rotate mixin -----*/
/*----- Translate 3D mixin -----*/
/*----- Vendorize mixin -----*/
/* Transition for animations */
/* Transition for animations - multiple */
/* Transition for animations */
/*---- Placeholder Color ----*/
*, *:before, *:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none !important;
}

html, body {
  height: 100%;
  margin: 0;
}

html {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 62.5%;
  -webkit-tap-highlight-color: transparent;
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;
  color: #000;
  outline-width: 0;
  outline: 0;
  background-color: #FFFFFF;
  font-size: 19px;
  font-size: 1.9rem;
}

body.noscroll {
  overflow: hidden;
}

body.ready .loader {
  display: none;
}

body.loading .page-inner > div:not(nav) {
  display: none;
}

body.loading footer {
  display: none;
}

h1, h2, h3, h4, h5 {
  padding: 0;
  margin: 0 0 20px 0;
}

ul, ol {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

p:last-child {
  margin-bottom: 0;
}

a, img {
  border: none;
  outline: none;
}

a {
  color: #000;
  text-decoration: underline;
  outline: none;
  border: none;
}

a:hover {
  text-decoration: none;
}

input, textarea, select:focus {
  outline: 0;
  border-radius: 0;
  -webkit-appearance: none;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  max-width: 1134px;
  padding: 0 22px;
}

@media only screen and (min-width: 768px) {
  .wrapper {
    max-width: 1162px;
    padding: 0 36px;
  }
}

.wrapper.full-height {
  height: 100%;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

div.page {
  overflow-x: hidden;
  overflow-y: visible;
}

[data-cover] {
  display: none;
}

.transparent {
  opacity: 0;
}

.bg-fixed {
  position: fixed;
  width: 200%;
  height: 200%;
  left: -100%;
  top: -100%;
  background-position: center center;
  background-size: cover;
}

nav.nav {
  height: 80px;
  position: relative;
  background: #fff;
  z-index: 101;
}

@media only screen and (min-width: 768px) {
  nav.nav {
    height: 160px;
  }
}

.nav-inner {
  height: 100%;
  width: 100%;
  position: relative;
}

.nav-logo {
  width: 100px;
  height: 42px;
  background-image: url("../img/logo.png");
  background-size: cover;
  position: absolute;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  margin: auto;
  bottom: 0;
}

@media only screen and (min-width: 768px) {
  .nav-logo {
    width: 201px;
    height: 85px;
  }
}

.nav-bar {
  position: absolute;
  display: block;
  width: 100%;
  bottom: 0;
  left: -100%;
  background: #ffe100;
  height: 5px;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

footer {
  width: 100%;
  background: #000;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 20px 0;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  footer {
    height: 179px;
    text-align: left;
  }
}

.footer-lines {
  width: 45%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: none;
}

@media only screen and (min-width: 768px) {
  .footer-lines {
    display: block;
  }
}

.footer-lines:before {
  position: relative;
  width: 100%;
  display: block;
  padding-bottom: 111.111111111%;
  background-image: url("../img/yellow-lines.png");
  content: '';
  background-size: cover;
}

@media only screen and (min-width: 768px) {
  .footer-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.footer-logo {
  width: 135px;
  height: 57px;
  background-image: url("../img/footer.png");
  background-size: cover;
  margin: 0 auto 15px;
}

@media only screen and (min-width: 768px) {
  .footer-logo {
    margin: inherit;
    float: left;
  }
}

.footer-text {
  color: #fff;
  font-family: "Sommet W01 Regular";
  font-size: 15px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .footer-text {
    padding-left: 80px;
    float: left;
  }
}

.footer-text:before {
  width: 1px;
  height: 100%;
  background: #fff;
  content: '';
  left: 40px;
  position: absolute;
  display: none;
}

@media only screen and (min-width: 768px) {
  .footer-text:before {
    display: block;
  }
}

.footer-links {
  padding: 10px 0 0;
}

.footer-links a {
  color: #fff;
  margin-right: 5px;
  padding-right: 10px;
  border-right: 1px solid #fff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-links a:last-child {
  border-right: none;
  padding-right: 0;
  margin-right: 0;
}

div.video {
  width: 100%;
  position: relative;
  background-size: cover;
  padding: 50px 0;
}

@media only screen and (min-width: 768px) {
  div.video {
    height: 518px;
    padding: 0;
  }
}

@media only screen and (min-width: 1441px) {
  div.video {
    height: 750px;
    padding: 0;
  }
}

div.video:after {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  content: '';
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.video-contents {
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  z-index: 1;
}

.video-contents-outer {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.video-contents-inner {
  width: 100%;
  max-width: 311px;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .video-contents-inner {
    max-width: 713px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .video-contents-inner {
    width: 502px;
  }
}

.video-intro {
  font-family: "Sommet W01 Light";
  font-size: 18px;
  padding-bottom: 15px;
  position: relative;
  float: left;
  clear: both;
}

@media only screen and (min-width: 768px) {
  .video-intro {
    font-size: 24px;
  }
}

.video-intro .video-intro-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100px;
  height: 5px;
  display: block;
  overflow: hidden;
}

@media only screen and (min-width: 768px) {
  .video-intro .video-intro-line {
    max-width: 200px;
  }
}

.video-intro .video-intro-line > span {
  position: absolute;
  bottom: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  background: #ffe100;
}

.video-headline {
  color: #ffe100;
  float: left;
  clear: both;
}

.video-headline-first {
  font-size: 20px;
  font-family: "Sommet W01 Black";
  text-transform: uppercase;
  line-height: 1;
  padding-right: 5px;
}

@media only screen and (min-width: 768px) {
  .video-headline-first {
    font-size: 60px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .video-headline-first {
    font-size: 40px;
    padding-right: 10px;
  }
}

.video-headline-second {
  color: #fff;
  font-family: "Sommet W01 Black";
  font-size: 55px;
  line-height: 1;
}

@media only screen and (min-width: 768px) {
  .video-headline-second {
    font-size: 140px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .video-headline-second {
    font-size: 100px;
  }
}

.video-headline-third {
  display: block;
  font-size: 42px;
  font-family: "Sommet W01 Black";
  text-transform: uppercase;
  line-height: 1;
  text-align: right;
}

@media only screen and (min-width: 768px) {
  .video-headline-third {
    font-size: 82px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .video-headline-third {
    font-size: 65px;
  }
}

.video + .grid {
  margin-top: -18px;
}

@media only screen and (min-width: 1441px) {
  .video + .grid {
    margin-top: -68px;
  }
}

.grid {
  width: 100%;
}

.grid-row {
  max-width: 1162px;
  margin: 0 auto;
}

.grid-row.has-columns {
  padding: 0 22px;
}

@media only screen and (min-width: 768px) {
  .grid-row.has-columns {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0 18px;
  }
}

.article {
  background: #fff;
  padding: 22px;
}

@media only screen and (min-width: 768px) {
  .article {
    padding: 50px 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .article {
    padding: 27px;
  }
}

.article-title {
  font-family: "Sommet W01 Bold";
  font-size: 38px;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 35px;
  line-height: 40px;
}

.article-title .article-line {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 82px;
  height: 7px;
  overflow: hidden;
}

.article-title .article-line > span {
  display: block;
  position: absolute;
  bottom: 0;
  content: '';
  width: 100%;
  height: 100%;
  background: #ffe100;
  left: -100%;
}

.article-content p {
  line-height: 24px;
}

.article-cta {
  padding-top: 20px;
}

.article-single {
  width: 100%;
  position: relative;
  float: left;
  padding-bottom: 22px;
}

@media only screen and (min-width: 768px) {
  .article-single {
    padding: 0 36px;
  }
}

@media only screen and (min-width: 768px) {
  .article-single {
    width: 50%;
    padding: 36px 18px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media only screen and (min-width: 768px) {
  .article-single-content {
    height: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .article-single-content .article {
    position: relative;
    padding-bottom: 67px;
  }
}

@media only screen and (min-width: 768px) {
  .article-single-content .article {
    height: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .article-single-content .article .cta {
    position: absolute;
    left: 0;
    padding: 27px;
    bottom: 0;
  }
}

.article-single-tbn {
  width: 100%;
}

.article-single-tbn-image {
  padding-bottom: 66.225165563%;
  background: #ccc;
  width: 100%;
  background-size: cover;
}

.article-featured {
  width: 100%;
  position: relative;
  padding: 0 22px 22px;
  z-index: 2;
}

@media only screen and (min-width: 768px) {
  .article-featured {
    padding: 0 36px;
  }
}

@media only screen and (min-width: 768px) {
  .article-featured.article-featured-two-column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.article-featured.article-featured-two-column .article-featured-tbn {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .article-featured.article-featured-two-column .article-featured-tbn {
    width: 50%;
    padding: 0;
  }
}

.article-featured.article-featured-two-column .article-featured-content {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .article-featured.article-featured-two-column .article-featured-content {
    width: 50%;
  }
}

.article-featured-tbn {
  width: 100%;
  padding-bottom: 66.225165563%;
  background: #ccc;
  background-size: cover;
  background-position: center center;
}

@media only screen and (min-width: 768px) {
  .article-featured-tbn {
    padding-bottom: 29.498525074%;
  }
}

.article-featured-content .article-cta {
  padding-top: 60px;
}

.brags {
  padding: 50px 0;
  position: relative;
  color: #fff;
  z-index: 2;
}

@media only screen and (min-width: 768px) {
  .brags {
    margin: 36px 0 0 0;
  }
}

.brags-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.brags-heading {
  color: #ffe100;
  font-family: "Sommet W01 Bold";
  font-size: 38px;
  text-align: center;
  padding: 0 0 55px 0;
  line-height: 40px;
}

.brags-columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

@media only screen and (min-width: 768px) {
  .brags-columns {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
}

.brags-column {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 0 0 38px;
}

@media only screen and (min-width: 768px) {
  .brags-column {
    width: 33.33%;
    padding: 0 18px;
  }
}

.brags-title {
  font-family: "Sommet W01 Regular";
  font-size: 30px;
  line-height: 38px;
  padding-bottom: 12px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.brags-title:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 3px;
  content: '';
  background: #ffe100;
  max-width: 250px;
}

.brags-content {
  margin: 0 auto;
  padding: 20px 0 0;
}

@media only screen and (min-width: 768px) {
  .brags-content {
    max-width: 240px;
  }
}

.cta {
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.cta:hover .cta-icon {
  background: #000;
}

.cta:hover .cta-icon:before, .cta:hover .cta-icon:after {
  background: #fff;
}

.cta:hover .cta-underline {
  text-decoration: none;
}

.cta-icon {
  width: 40px;
  height: 40px;
  border: 1px solid #000;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  position: relative;
  display: block;
}

.cta-icon:before, .cta-icon:after {
  position: absolute;
  content: '';
  background: #000;
  width: 16px;
  height: 1px;
  top: 0;
  bottom: 0;
  left: 9px;
  margin: auto;
}

.cta-icon:before {
  -webkit-transform-origin: top right;
          transform-origin: top right;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.cta-icon:after {
  -webkit-transform-origin: top right;
          transform-origin: top right;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.cta-text {
  padding-left: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 16px;
}

@media only screen and (min-width: 768px) {
  .cta-text {
    font-size: 19px;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .cta-text {
    font-size: 16px;
    padding-left: 10px;
  }
}

.cta-underline {
  text-decoration: underline;
  font-weight: bold;
}

.loader {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .grid-row.has-columns {
    display: inherit;
  }
}

/*# sourceMappingURL=main.css.map */
